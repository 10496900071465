.btn-uvs {
  height: 6px;
  width: 6px;
  padding: 0;
  margin-right: 10px;
}

.pagination {
  margin: 10px auto;
  display: contents;
}
.react-bs-table-pagination {
  margin-top: 10px!important;
  padding: 0 15px!important;
}
table {
  width: 100%;
}
.rupees {
  padding: 5px;
}
.table-responsive {
  overflow-x: hidden;
  overflow-y: hidden;
}
button#pageDropDown {
  width: 100px;
}
.totalvs {
  padding: 10px;
  text-align: center;
  border-top: 1px solid #eee;
}
.form-control-uvs {
  outline: inherit;
}
.form-control {
  outline: inherit;
}
.btn-circle {
  border-radius: 50%;
  background: #50bf93;
  width: 0px;
  padding: 2px;
  margin: 3px;
}
.rupeeicon {
  color: black;
}
@media (max-width: 575.98px) {
  .tablestyle{
    font-size: 10px;
  }
  .react-bs-table-bordered{
    font-size: 10px;
    width: 75%;
  }
  .table{
   width: 128%;
  }
  .button-click{
    background: #ffbc00;
    color: white;
    margin-left: -8px;
    border: none;
  }
  .mobile-view-heading{
    font-size: 10px;
  }
  .mobile-view-body{
    font-size: 10px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
