.btn-uvs {
  height: 6px;
  width: 6px;
  padding: 0;
  margin-right: 10px;
}

.form-control {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 2px;
  margin-bottom: 25px;
  border: 0;
  margin-top: 8px;
}
.btn-default {
  background: #dfe9ff;
  color: #5185ff;
}
.card.shadow.mb-4.uvsbtm {
  padding: 40px;
}
.error-div {
  color: red;
}
.country-code {
  position: absolute;
  top: 32px;
  left: 1px;
  border-right: 1px solid #d1d1d1;
  font-size: 1.1em;
  padding: 5px;
}
 
.asterics{
  color: green;
}