
@keyframes open {
  0% {
    background-position: 166% -25%, 220% -69%;
  }
  100% {
    background-position: 120% -5%, 200% -40%;
  }
}
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.field {
  margin: 5px;
  display: flex;
  flex-direction: column;
}
input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 25px;
  cursor: pointer;
  border: dashed;
}

.img-wrap {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
.img-upload:before {
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}
.img-upload:hover:before {
  opacity: 1;
}
img {
  width: auto;
}

label {
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
}

input {
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
  font-size: 18px;
  transition: 0.2s;
}
input:focus {
  outline: none;
  border: 1px solid #64d488;
}
input::placeholder {
  color: #bebebe;
}
.name {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
  max-width: 220px;
  overflow-wrap: break-word;
}
.status {
  text-align: center;
  max-width: 220px;
  overflow-wrap: break-word;
}
.save {
  font-weight: 600;
  left: -20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 20px;
  box-shadow: 20px 0px 40px 0px #63d3a6;
}
.edit {
  color: #fff;
  width: 180px;
}
.info-save-button {
  width: 15%;
  margin-top: 20px;
}
button:focus {
  outline: none;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.fa-instagram {
  position: absolute;
  color: #79f1a4;
  top: 3%;
  right: 2%;
  font-size: 38px;
}
.fa-instagram:hover {
  font-size: 42px;
  color: #caff81;
  transition: all 0.1s linear;
  cursor: pointer;
}
.country-code {
  position: absolute;
  top: 32px;
  left: 1px;
  border-right: 1px solid #d1d1d1;
  font-size: 1.1em;
  padding: 5px;
}
.error-div {
  color: red;
}
