.uvsimgs {
  width: 40px;
  height: 60px;
  float: left;
}
b.uvsbwhite {
  font-weight: 700;
  color: #fff;
  margin-right: 6px;
  font-size: 18px;
}
.uvsbwhiteimg {
  margin-right: 5px;
  margin-top: -5px;
}
.myApplications{
  display:"flex";
  flex-direction:"row";
  justify-content:"start";
  margin: "5px";
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
@media (max-width: 575.98px) {
  .myapp{
    margin-top: -50px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .myapp{
    margin-top: -50px;
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .appcard {
    min-height: 125px;
  }

}
.uvsblue,
.uvsred,
.uvsorange {
  margin-bottom: 10px;
}
.btn-uvs {
  height: 6px;
  width: 6px;
  padding: 0;
  margin-right: 10px;
}
.btn-circle {
  border-radius: 50%;
  background: #50bf93;
  width: 0px;
  padding: 3px;
}
.linkby {
  color: white;
  font-size: 80%;
  font-weight: 400;
}

.react-bs-table-bordered {
  border: unset !important;
}

.no-data-dashboard {
  margin-top: 5px;
  display: inline-block;
}
.linktext {
  color: white;
  text-decoration: underline;
}
.appname{
  font-size: 15px;
  font-weight: bold;
  color: black;
  left: "20px";
  right: "40px";
 

}