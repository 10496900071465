.button-stripe {
  background-color: #004a7f;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 20px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
  margin: 35px 35px 35px 701px;
}
@-webkit-keyframes glowing {
  0% {
    background-color: #f9841d;
    /* -webkit-box-shadow: 0 0 3px #b20000; */
  }
  50% {
    background-color: #f9841d;
    /* -webkit-box-shadow: 0 0 40px #ff0000; */
  }
  100% {
    background-color: #f9841d;
    /* -webkit-box-shadow: 0 0 3px #b20000; */
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #f9841d;
    /* -moz-box-shadow: 0 0 3px #b20000; */
  }
  50% {
    background-color: #f9841d;
    /* -moz-box-shadow: 0 0 40px #ff0000; */
  }
  100% {
    background-color: #f9841d;
    /* -moz-box-shadow: 0 0 3px #b20000; */
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #f9841d;
    /* box-shadow: 0 0 3px #b20000; */
  }
  50% {
    background-color: #f9841d;
    /* box-shadow: 0 0 40px #ff0000; */
  }
  100% {
    background-color: #f9841d;
    /* box-shadow: 0 0 3px #b20000; */
  }
}

@keyframes glowing {
  0% {
    background-color: #f9841d;
    /* box-shadow: 0 0 3px #b20000; */
  }
  50% {
    background-color: #f9841d;
    /* box-shadow: 0 0 40px #ff0000; */
  }
  100% {
    background-color: #f9841d;
    /* box-shadow: 0 0 3px #b20000; */
  }
}
@media (max-width: 575.98px) {
  .button-stripe {
    background-color: #004a7f;
    /* border-radius: 10px; */
    border: none;
    /* color: #ffffff; */
    cursor: pointer;
    /* display: inline-block; */
    font-family: Arial;
    font-size: 13px;
    padding: 9px 10px;
    text-align: center;
    text-decoration: none;
    animation: glowing 1500ms infinite;
    margin: 31px 16px 39px 7px;
    /* position: fixed; */
    /* word-break: normal; */
    /* word-spacing: normal; */
    word-break: keep-all;
  }
  @-webkit-keyframes glowing {
    0% {
      background-color: #f9841d;
      /* -webkit-box-shadow: 0 0 3px #b20000; */
    }
    50% {
      background-color: #f9841d;
      /* -webkit-box-shadow: 0 0 40px #ff0000; */
    }
    100% {
      background-color: #f9841d;
      /* -webkit-box-shadow: 0 0 3px #b20000; */
    }
  }

  @-moz-keyframes glowing {
    0% {
      background-color: #f9841d;
      /* -moz-box-shadow: 0 0 3px #b20000; */
    }
    50% {
      background-color: #f9841d;
      /* -moz-box-shadow: 0 0 40px #ff0000; */
    }
    100% {
      background-color: #f9841d;
      /* -moz-box-shadow: 0 0 3px #b20000; */
    }
  }

  @-o-keyframes glowing {
    0% {
      background-color: #f9841d;
      /* box-shadow: 0 0 3px #b20000; */
    }
    50% {
      background-color: #f9841d;
      /* box-shadow: 0 0 40px #ff0000; */
    }
    100% {
      background-color: #f9841d;
      /* box-shadow: 0 0 3px #b20000; */
    }
  }

  @keyframes glowing {
    0% {
      background-color: #f9841d;
      /* box-shadow: 0 0 3px #b20000; */
    }
    50% {
      background-color: #f9841d;
      /* box-shadow: 0 0 40px #ff0000; */
    }
    100% {
      background-color: #f9841d;
      /* box-shadow: 0 0 3px #b20000; */
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* // Medium devices (tablets, 768px and up) */
.username {
  margin: 5px;
}
.logoutmodal-close {
  float: left;
  margin-left: -164px;
}
.style-content {
  width: 120%;
  margin: 0 auto;
}
.logout-button {
  margin-left: -213px;
  margin-top: 75px;
}
.cancle-button {
  margin-top: -53px;
  margin-left: 158px;
}
