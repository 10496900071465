.btn-uvs {
  height: 6px;
  width: 6px;
  padding: 0;
  margin-right: 10px;
}

.pagination {
  margin: 10px auto;
}
.modal {
  top: 0%;
}
 

.uvsshow {
  padding: 10px;
  border: 0;
  border-radius: 2px;
}
.uvsdrop {
  background: #fff;
  border: 0;
}
.uvsdrop::after {
  content: none;
}
.uvsshow a {
  color: #001e4b;
}
.modal-content {
  border-radius: 0;
}
.modal-body {
  padding: 30px;
}
.modal-header {
  padding: 15px 30px;
}
.modal-body .form-control {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 2px;
  margin-bottom: 15px;
  border: 0;
}
.btn-default {
  background: #dfe9ff;
  color: #5185ff;
}
.modal-body .btn {
  margin-bottom: 15px;
}
.tuvsable .form-control {
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 0;
  font-size: 12px;
}
#myModaluvsassign .tuvsable tr td,
#myModaluvsassign .tuvsable tr th {
  border: 0;
  padding: 10px 20px;
  font-size: 14px;
}
.uvsappss .card {
  padding: 0;
}
.table-borderless td,
.table-borderless th {
  border: 0;
}
.table-responsive {
  overflow-x: hidden;
  overflow-y: hidden;
}
.react-bs-table-pagination div:first-child > div:nth-child(2) {
  display: flex !important;
  flex: 0 0 100%;
  max-width: 100%;
}
body {
  font-family: "Varela Round", sans-serif;
}
.modal-confirm {
  color: #636363;
  width: 400px;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}
.modal-confirm .modal-footer a {
  color: #999;
}
.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}
.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
}
.modal-confirm .btn-secondary {
  background: #c1c1c1;
}
.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}
.modal-confirm .btn-danger {
  background: #f15e5e;
}
.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}
.error-div {
  color: red;
}
.btn-uvs {
  height: 6px;
  width: 6px;
  padding: 0;
  margin-right: 10px;
}
table {
  width: 100%;
}
.uvsshow {
  padding: 10px;
  border: 0;
  border-radius: 2px;
}
.uvsdrop {
  background: #fff;
  border: 0;
}
.uvsdrop::after {
  content: none;
}
.uvsshow a {
  color: #001e4b;
}
.modal-content {
  border-radius: 0;
}
.modal-header {
  padding: 15px 30px;
}
.modal-body .form-control {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 2px;
  margin-bottom: 15px;
  border: 0;
}
.btn-default {
  background: #dfe9ff;
  color: #5185ff;
}
.modal-body .btn {
  margin-bottom: 15px;
}
.tuvsable .form-control {
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 0;
  font-size: 12px;
}
#myModaluvsassign .tuvsable tr td,
#myModaluvsassign .tuvsable tr th {
  border: 0;
  padding: 10px 20px;
  font-size: 14px;
}
.uvsappss .card {
  padding: 0;
}
.infoclass {
  width: 50%;
  font-size: 10px;
}
.dangerclass {
  width: 50%;
  padding: 2px;

}
@media (max-width: 575.98px) {
  .dangerclass {
    font-size: 10px;
  }
  .infoclass {
    font-size: 10px;
  }
}
.country-code-user {
  position: absolute;
  top: 32px;
  left: 1px;
  border-right: 1px solid #d1d1d1;
  font-size: 1.1em;
  padding: 5px;
}
 
#savelink{
  /* background: blue;
    padding: 8px; */
   
    height: 42px;
    width: 276%;
    margin-top: 40px;
}
.select-padding{
  padding: 5px;
  margin-top: 0px;
  width: 100%;
}
.role{
  margin-top: 0px;
  margin-right: 400px;
}
.user-modal{
  top: 0;
}