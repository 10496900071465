.topbar {
  height: 4.375rem;
}

.uvsimg {
  width: 50%;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
}

footer.sticky-footer {
  display: none;
}

.uvsroundbg {
  height: 100%;
  position: relative;
}

body {
  background: #fff;
  color: #7e7e7e;
}

.uvsround {
  padding: 5% 9%;
}

h3 {
  font-weight: bold;
  color: #001e4b;
}

.form-control {
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  margin-bottom: 10px;
}


.error-div {
  color: red;
}

.country-code {
  position: absolute;
  top: 32px;
  left: 1px;
  border-right: 1px solid #d1d1d1;
  font-size: 1.1em;
  padding: 5px;
}

@media only screen and (max-width: 780px) {
  .uvsroundbg {
    display: none;
  }

  .form-control {
    margin-bottom: 10px;
    width: 100%;
  }
}


@media (max-width: 575.98px) {
  .uvsroundbg {
    display: none;
  }

  .form-control {
    margin-bottom: 10px;
  }
}