body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0px !important;
  overflow: -moz-hidden-unscrollable !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* // Extra small devices (portrait phones, less than 576px) */

.modal {
  top: 30%;
}
@media (max-width: 575.98px) {
  #sidebaruv {
    position: absolute;
    z-index: 1;
  }
  .react-bs-table .table-bordered tr td,
  .react-bs-table .table-bordered tr th {
    padding: 2px !important;
  }

  .modal {
    top: 25%;
  }
  .modal-for-mobile {
    top: 10px;
  }
  .close-icon {
    display: block;
  }
  .button-stripe {
    background-color: #004a7f;
    border: none;
    cursor: pointer;
    font-family: Arial;
    font-size: 13px;
    padding: 9px 10px;
    text-align: center;
    text-decoration: none;
    animation: glowing 1500ms infinite;
    margin: 31px 16px 39px 7px;
    word-break: keep-all;
  }
  @-webkit-keyframes glowing {
    0% {
      background-color: #ffcc00;
    }
    50% {
      background-color: #ffcc00;
    }
    100% {
      background-color: #ffcc00;
    }
  }

  @-moz-keyframes glowing {
    0% {
      background-color: #ffcc00;
    }
    50% {
      background-color: #ffcc00;
    }
    100% {
      background-color: #ffcc00;
    }
  }

  @-o-keyframes glowing {
    0% {
      background-color: #ffcc00;
    }
    50% {
      background-color: #ffcc00;
    }
    100% {
      background-color: #ffcc00;
    }
  }

  @keyframes glowing {
    0% {
      background-color: #ffcc00;
    }
    50% {
      background-color: #ffcc00;
    }
    100% {
      background-color: #ffcc00;
    }
  }
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Style the sidenav links and the dropdown button */
.sidenav a, .dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

/* On mouse-over */
.sidenav a:hover, .dropdown-btn:hover {
  color: #f1f1f1;
}

/* Main content */
.main {
  margin-left: 200px; /* Same as the width of the sidenav */
  font-size: 20px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

/* Add an active class to the active dropdown button */
.active {
  background-color: green;
  color: white;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}

/* Some media queries for responsiveness */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}