.uvsborder {
  border-right: 2px dashed #ccc;
}
.btnvss {
  background: #eff1ff;
  color: #5185ff;
  border-radius: 0;
}
.btnvss1 {
  background: transparent linear-gradient(276deg, #f6dd61 0%, #ffc93e 100%) 0%
    0% no-repeat padding-box;
  color: #ffffff;
  border-radius: 0;
}
.btnvss2 {
  background: transparent
    linear-gradient(279deg, #bdc1b9 0%, #989796 53%, #777277 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  border-radius: 0;
}
.btn-uvs {
  height: 6px;
  width: 6px;
  padding: 0;
  margin-right: 10px;
}
.uvsplul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 36px;
  font-size: 12px;
  min-height: 108px;
  max-height: 110px;
  overflow-y: auto;
}
.uvsplanss tr td {
  padding: 10px 20px;
  font-weight: 600;
}
.uvsplanss tr td p {
  font-size: 12px;
}
.uvsright {
  float: right;
  font-size: 15px;
}
.uvsplanss tr td .text-uvsh {
  margin-bottom: 15px !important;
}
.form-control-uvs {
  border: 0;
  padding: 4px 8px 4px 8px;
  background: #fff;
  font-size: 12px;
  font-weight: bold;
  float: right;
  border-radius: 20px;
}

.uvsplanss {
  max-height: 380px;
  width: 100%;
}
.platinumplan {
  max-height: 380px;
  width: 100%;
}
@media (max-width: 575.98px) {
  .subheight {
    margin: 32px 0 0 0;
  }
  .expires {
    margin-right: 24px;
  }
  .right-check {
    margin: 0px -24px 0 0;
  }
}

.heading {
  font-size: 20px;
}
.payment-loader {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2a2a2ad1;
  z-index: 10;
}
.install-loader {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    background: #2a2a2ad1;
    z-index: 10;
  
}
.right-check {
  color: green;
  font-size: 20px;
  margin-left: 10px;
}
.billing {
  margin-left: 12px;
}
.you-paid {
  font-size: 12px;
}
.plan-amount {
  font-size: 15px;
  font-weight: bold;
}
.discount {
  font-size: 15px;
  font-weight: bold;
   
   
}
.expiry-date{
color: gray;
font-size: 11px;
}