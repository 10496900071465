.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.warning {
  background: #e74a3b;
  width: 100%;
  color: #fff;
  text-align: center;
  display: inline-block;
}
.buynow {
  color: white;
  text-decoration: underline;
}
.renew-now {
  color: #fff;
}
.renew {
  text-decoration: underline;
}
@media (max-width: 575.98px) {
  .button-stripe {
    background-color: #004a7f;
    border: none;
    cursor: pointer;
    font-family: Arial;
    font-size: 13px;
    padding: 9px 10px;
    text-align: center;
    text-decoration: none;
    animation: glowing 1500ms infinite;
    margin: 31px 16px 39px 7px;
    word-break: keep-all;
  }
  @-webkit-keyframes glowing {
    0% {
      background-color: #ffcc00;
    }
    50% {
      background-color: #ffcc00;
    }
    100% {
      background-color: #ffcc00;
    }
  }

  @-moz-keyframes glowing {
    0% {
      background-color: #ffcc00;
    }
    50% {
      background-color: #ffcc00;
    }
    100% {
      background-color: #ffcc00;
    }
  }

  @-o-keyframes glowing {
    0% {
      background-color: #ffcc00;
    }
    50% {
      background-color: #ffcc00;
    }
    100% {
      background-color: #ffcc00;
    }
  }

  @keyframes glowing {
    0% {
      background-color: #ffcc00;
    }
    50% {
      background-color: #ffcc00;
    }
    100% {
      background-color: #ffcc00;
    }
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
