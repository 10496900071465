#close-sidebar {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
}

@media (max-width: 575.98px) {
  .close-icon {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .close-icon {
    display: none;
  }
}

.logout-pointer {
  cursor: pointer;
  padding: 15px;
  text-decoration: underline;
}

.logout-pointer:hover {
  background-color: #1c204e;
  color: #4e73df;
  text-decoration: none;
}

.content-style {
  width: 105%;
}

.logoutmodal-close {
  float: left;
  margin-left: -164px;

}

.logout-button {
  margin-left: -170px;
  margin-top: 58px;
}

.cancle-button {
  margin-top: -54px;
  margin-left: 169px;
}


.notification-menu {
  margin-top: -23px;
  margin-left: 29px;
  background: none;
  font-size: 16px;
}

.bell-icon {
  font-size: 21px;
  margin-left: 19PX;
  COLOR: BLUE;
}

#main-menu {
  background-color: #2E3039;
}

.list-group-item {
  background-color: #2E3039;
  border: none;
}

a.list-group-item {
  color: #FFF;
}

a.list-group-item:hover,
a.list-group-item:focus {
  background-color: #43D5B1;
}

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  color: #FFF;
  background-color: #43D5B1;
  border: none;
}

.list-group-item:first-child,
.list-group-item:last-child {
  border-radius: 0;
}

.list-group-level1 .list-group-item {
  padding-left: 30px;
}

.list-group-level2 .list-group-item {
  padding-left: 60px;
}