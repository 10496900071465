
.topbar {
  height: 4.375rem;
}
.uvsimg {
  width: 70%;
  margin-top: 20%;
}
footer.sticky-footer {
  display: none;
}
.uvsroundbg {
  height: 100vh;
}

body {
  background: #fff;
  color: #7e7e7e;
}
.uvsround {
  padding: 9%;
}
h3 {
  font-weight: bold;
  color: #001e4b;
}
.form-control {
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}
@media only screen and (max-width: 780px) {
  .uvsroundbg {
    display: none;
  }
}
.form-control {
  margin-bottom: 10px;
}
.error-div {
  color: red;
}
