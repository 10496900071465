.uvsappss h5 {
  margin: 20px 0;
  color: #001e4b;
  font-weight: bold;
}
.uvsappss .card {
  padding: 20px 10px;
}
.uvsappss h5 {
  margin: 20px 0;
  font-size: 13px;
  color: #001e4b;
  font-weight: bold;
}
.uvsappss .card {
  padding: 20px 10px;
}
.linkapp {
  margin-left: 10px;
}
/* .installLoaderFirst {
  flex: 1;
  justify-content: "center";
  align-items: "center";

  width: "100vw";
 
  height: "50h";
  position: "absolute";
 

  z-index: 5;
  opacity: 0.8;
}
.installLoaderSecond{
  display: 'flex';
  position: 'relative';
  justify-content: "center";
  align-items: "center";

  width: "100vw";
 
  height: "50h";
  

  z-index: 5;
  opacity: 0.8;
}
@media (max-width: 575.98px) {
  .installLoader {
    width: "50vw";
  }
  .installLoaderSecond{
    width: "50vw";
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .installLoader {
    width: "50vw";
  }
  .installLoaderSecond{
    width: "50vw";
  }
} */
