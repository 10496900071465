.main-email-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  border: 1px solid blanchedalmond;
  padding: 20px;
}

.forget-form {
  align-items: flex-start;
  font-weight: medium;
}

.forget-heading {
  font-size: x-large;
  font-weight: bold;
}

.forget-btn {
  display: inline;
}

.spacing {
  margin: 0 4px 0 4px;
}

@media (max-width: 575.98px) {
  .forget-form {
    align-items: flex-start;
    font-size: small;
  }

  .forget-heading {
    font-size: 17px;
    font-weight: bold;
  }

  .spacing {
    margin: 8px 8px 8px 4px;
  }

  .form-control {
    width: auto;
  }
}